import {Translations} from './translations'

export const de : Translations = {
page: {
footer: {
save: 'Speichern',
partsList: 'Stückliste',
download: {
label: 'Download',
withTexture: '3D mit Texture (.glb)',
withoutTexture: '3D ohne Texture (.obj)',
dimensions: '2D Vermassung (.pdf)',
},
exclVAT: 'exkl. MwSt.',
orderRequest: 'Bestellanfrage',
},
header: {
undo: 'Undo',
redo: 'Redo',
center: 'Zentrieren',
closeDoors: 'Türen schliessen',
openDoors: 'Türen öffnen',
configure: 'Konfigurieren',
view3D: '3D-Ansicht',
frontView: 'Frontseite',
switchToFrontView: 'Wechsel zur Frontseite',
rearView: 'Rückseite',
switchToRearView: 'Wechsel zur Rückseite',
},
items: {
construction: 'Aufbau',
depth: 'Tiefe',
withRolls: 'mit Rollen',
withSideCovers: 'mit Seitenabdeckung',
withBackToBack: 'mit Back2Back',
withFixedTopTray: 'mit Abschlusstablar durchgehend',
interior: 'Ausstattung',
front: 'Stauraum',
},
actions: {
addColumn: 'Spalte hinzufügen',
moveColumnToLeft: 'Spalte nach links verschieben',
duplicateColumn: 'Spalte duplizieren',
deleteColumn: 'Spalte löschen',
moveColumnToRight: 'Spalte nach rechts verschieben',
changeColumnWidth: 'Spaltenbreite ändern',
changeColumnHeight: 'Spaltenhöhe ändern',
},
selectedItem: {
color: 'Farbe',
applyToAll: 'Für alle übernehmen',
price: 'Einzelpreis',
remove: 'Entfernen',
backWall: 'Rückwand',
trayReinforcement: {
name: 'Verstärkung',
none: 'keine',
upTo35kg: 'bis 35kg',
upTo100kg: 'bis 100kg',
},
withFeltMat: 'mit Filzmatte',
withLinoleumCover: 'mit Linoleum-Abdeckung',
frontColor: 'Frontfarbe',
},
saveDesignDialog: {
title: 'Design speichern',
text: 'Erhalten Sie einen Link zu Ihrer Konfiguration per E-Mail. Damit können Sie Ihr Design jederzeit weiter bearbeiten.',
cancel: 'Abbrechen',
save: 'Design speichern',
email: 'E-Mail',
message: {
title: 'E-Mail versendet',
text: 'Ein E-Mail mit einem Link zur Konfiguration wurde versendet.',
},
},
partsListDialog: {
title: 'Stückliste',
total: 'Total (exkl. MwSt.)',
columns: {
partNr: 'Artikel-Nr.',
description: 'Bezeichnung',
color: 'Farbe',
dimension: 'Dimension',
price: 'Preis',
quantity: 'Anzahl',
totalPrice: 'Totalpreis',
},
subtotal: 'Zwischentotal',
additionalColorCost: 'Aufpreis Farbe',
},
orderDialog: {
title: 'Bestellanfrage',
cancel: 'Abbrechen',
request: 'Anfragen',
labels: {
name: 'Vorname, Name (Ansprechsperson)',
company: 'Firma',
section: 'Bereich',
private: 'Privat',
retailer: 'Händler',
architect: 'Architekt',
email: 'E-Mail',
address: 'Adresse',
zipCodeAndCity: 'PLZ Ort',
phone: 'Telefon',
picking: 'Kommission',
notes: 'Bemerkungen',
},
message: {
title: 'Anfrage versendet',
text: 'Ein E-Mail mit einem Link zur Konfiguration wurde versendet.',
},
},
validationMessages: {
required: 'Eingabe erforderlich',
email: 'Ungültige E-Mailadresse',
},
materialSelections: {
frameColor: 'Rahmenfarbe',
accentColor: 'Frontfarbe',
},
},
materials: {
Vernickelt: 'vernickelt',
AluminiumBlank: 'blank',
AluminiumEloxiert: 'farblos eloxiert',
Brushed: 'gebürstet',
Zinkgelb: 'zinkgelb',
Currygelb: 'currygelb',
Blutorange: 'blutorange',
Schwarzrot: 'schwarzrot',
Perlkupfer: 'perlkupfer',
Tannengrün: 'tannengrün',
Perlweiss: 'perlweiss',
Perlbeige: 'perlbeige',
Beigegrau: 'beigegrau',
Graubraun: 'graubraun',
Anthrazit: 'anthrazit',
Tiefschwarz: 'schwarz (tiefschwarz)',
Signalweiss: 'weiss (signalweiss)',
Schwarz: 'Linoleum schwarz',
Pebble: 'Linoleum pebble',
Eiche: 'Holzfurnier Eiche',
Multiplex: 'Multiplex',
Chrome: 'chrom',
FeltAnthrazit: 'anthrazit',
BlechVerzinkt: 'verzinkt',
BlechBlumigVerzinkt: 'blumig verzinkt',
WeissAluminium: 'weissaluminium',
LehniBlau: 'lehni blau',
Mirror: 'spiegel',
Net: 'netz',
},
articles: {
shelf: {
SideWall: 'Regalseite',
Foot: 'Fussteil',
StabilityCross: 'Stabilitätskreuz',
Tray: 'Tablar',
Bookend: 'Bücherstütze',
ColumnBackWall: 'Ganze Rückwand',
PullOutTray: 'Auszugtablar',
InnerTray: 'Innentablar zu Schiebe- / Einschubtüre',
DiagonalTray: 'Schrägtablar',
SlideInDoor: 'Einschubtüre',
SlidingDoor: 'Schiebetüre',
DoorBackWall: 'Einzelne Rückwand',
Drawer: 'Schublade',
SideCover: 'End-Seite',
ConnectingSleeve: 'Verbindungshülse',
TableBoard: 'Schreibtischplatte',
ClothesRail: 'Kleiderstange',
TrayEnforcment: 'Verstärkung zu Tablar',
FeltMat: 'Filzmatte',
SideWallTopCover: 'Abdeckschiene',
RollsFrame: 'Rahmen mit Lenkrollen',
PreconfiguredShelf: 'Aluminium-Regal',
FixedTopTray: 'Tablar oben für back to back Regale',
LinoleumCover: 'Linoleum-Abdeckung',
FixedTopTrayLinoleumCover: 'Linoleum-Abdeckung für back to back regale',
},
wardrobe: {
ShoeLocker10: {
name: 'Schuhkippe',
variant: 'breit (10 Kippen)',
},
ShoeLocker5: {
name: 'Schuhkippe',
variant: 'schmal (5 Kippen)',
},
ShoeLocker4: {
name: 'Schuhkippe',
variant: 'schmal (4 Kippen)',
},
MirrorCabinetNets: {
name: 'Spiegelschrank',
variant: '1 Tablar, 4 Nylonnetze schwarz',
},
MirrorCabinetTrays: {
name: 'Spiegelschrank',
variant: '7 Tablare',
},
NetContainer: {
name: 'Netz- / Tablarcontainer',
variant: '5 Nylonnetze schwarz',
},
TrayContainer: {
name: 'Netz- / Tablarcontainer',
variant: '7 Tablare',
},
FoldingWardrobe: {
name: 'Klapp-Garderobe',
},
},
},
mail: {
order: {
subject: 'Lehni Offertanfrage Produkt:',
requestText: 'Wir danken Ihnen für Ihre Anfrage. Untenstehend finden Sie eine vermasste Skizze und die Stückliste der verwendeten Einzelteile. Allfällige Fragen werden wir Ihnen baldmöglichst beantworten.',
lehniText: 'Der Vertrieb erfolgt über unsere Fachhandelspartner <a href="https://lehni.ch/">lehni.ch</a>.',
linkText: {
text: '{link} können Sie nochmals Ihre Konfiguration anschauen.',
linkLabel: 'Hier',
},
contact: 'Kontaktdaten',
labels: {
name: 'Name',
company: 'Firma',
section: 'Bereich',
email: 'E-Mail',
address: 'Adresse',
zipCodeAndCity: 'PLZ Ort',
phone: 'Telefon',
picking: 'Kommission',
notes: 'Bemerkungen',
},
},
partsList: {
title: 'Stückliste',
depth: 'Regaltiefe',
columns: {
partNr: 'Artikel-Nr.',
description: 'Bezeichnung',
color: 'Farbe',
dimension: 'Grösse',
price: 'Preis',
quantity: 'ME',
totalPrice: 'Totalpreis',
},
subtotal: 'Zwischentotal',
additionalColorCost: 'Aufpreis Farbe',
total: 'Total (exkl. MwSt.)',
dimension: 'Masse',
},
saveDesign: {
subject: 'Ihr gespeichertes Lehni Produkt:',
text: 'Besten Dank, dass Sie sich für ein Lehni Produkt interessieren. {link} können Sie Ihre Konfiguration anschauen und weiterbearbeiten.',
linkLabel: 'Hier',
},
},
pdf: {
linkText: {
text: 'Die Konfiguration {commissionNr} ist {link} gespeichert',
linkLabel: 'hier',
},
partsList: {
title: 'Stückliste',
depth: 'Regaltiefe',
columns: {
partNr: 'Artikel-Nr.',
description: 'Bezeichnung',
color: 'Farbe',
dimension: 'Grösse',
price: 'Preis',
quantity: 'ME',
totalPrice: 'Totalpreis',
},
subtotal: 'Zwischentotal',
additionalColorCost: 'Aufpreis Farbe',
total: 'Total (exkl. MwSt.)',
}
}
}
